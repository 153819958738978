import React from "react"

import './home.css';
import SEO from '../components/seo';
import Image from '../components/image';

export default () => <div id="content">
  <SEO title="Home" />
  <div id="intro">
    <h1>Milad Karbasizadeh</h1>
    <h2>Software developer, Scrum master</h2>
    <div id="links">
      <ul>
        <li id="github"><a rel="noreferrer" target="_blank" href="https://github.com/miladkdz/"><Image filename="github.png" alt="Github profile"/></a></li>
        <li id="stackoverflow"><a rel="noreferrer" target="_blank" href="https://stackoverflow.com/story/miladkarbasizadeh/"><Image filename="so.png" alt="Stackoverflow profile"/></a></li>
        <li id="twitter"><a rel="noreferrer" target="_blank" href="https://twitter.com/miladkzadeh/"><Image filename="twitter.png" alt="Twitter profile"/></a></li>
        <li id="linkedin"><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/miladkarbasizadeh/"><Image filename="linkedin.png" alt="LinkedIn profile"/></a></li>
      </ul>
    </div>
  </div>
</div>
